<template>
    <main
        class="pb-12 overflow-x-hidden relative transition-all"
        :class="$store.state.globals.isHeaderThin && 'xl:-translate-y-11'"
    >
        <div class="relative container min-h-[80vh] lg:min-h-[72vh] 3xl:min-h-[70vh] flex justify-center">
            <div
                class="lg:rounded-[1.75rem] overflow-hidden carousel absolute 3xl:relative w-full transition-all mt-0 lg:mt-6"
            >
                <div id="main-carousel" class="swiper 3xl:rounded-2xl overflow-hidden">
                    <div class="swiper-wrapper">
                        <div v-for="slide in carousel" :key="`slide-${slide.id}`" class="swiper-slide">
                            <div
                                class="relative min-h-[80vh] lg:min-h-[72vh] max-h-[72vh] carousel-inner flex items-start lg:items-center"
                            >
                                <div
                                    v-if="slide?.theme"
                                    class="carousel-inner-overlay"
                                    :class="slide?.theme === 'dark' && 'carousel-inner-overlay--dark'"
                                ></div>
                                <img
                                    class="absolute left-0 top-0 h-full w-full object-cover z-[-1]"
                                    :src="$device.isMobile ? slide.mobile_image : slide.image"
                                    :alt="slide?.title_hu"
                                    data-not-lazy
                                />
                                <div
                                    class="text-white container mr-auto z-1 relative pt-12 lg:pt-0 transition-all swiper-inner-wrapper"
                                >
                                    <div class="md:w-9/12 xl:w-6/12 lg:pl-16 xl:pl-20 3xl:pl-24">
                                        <h1
                                            class="text-2xl lg:text-3xl xl:text-[2.25rem] 3xl:text-[2.5rem] mb-4 lg:mb-5 leading-[1.25] xl:leading-[1.4] 3xl:leading-[1.2] font-Inter-Bold"
                                        >
                                            {{ slide?.title_hu }}
                                        </h1>
                                        <div
                                            v-if="slide.lead"
                                            class="mb-5 lg:mb-6 text-base lg:text-lg xl:text-xl 3xl:text-2xl 3xl:leading-[1.29] font-Inter-Semibold lg:font-Inter-Regular opacity-80"
                                            v-html="slide.lead"
                                        ></div>
                                        <div
                                            class="flex items-start lg:items-center flex-wrap lg:flex-nowrap gap-y-4 lg:gap-y-0 lg:flex-row gap-x-6 lg:mb-16 xl:mb-0"
                                        >
                                            <PButton
                                                v-for="(link, index) in slide.externalLinks"
                                                :key="`external-link-${index}`"
                                                :label="link.label"
                                                :appearance="link.color === 'white' ? 'white' : 'yellow'"
                                                external
                                                :to="link.url"
                                            />
                                            <PButton
                                                v-if="slide?.link"
                                                :label="slide.link_text"
                                                appearance="yellow"
                                                external
                                                :to="slide.link"
                                            />
                                            <PButton
                                                v-if="slide?.link2"
                                                :label="slide.link2_text"
                                                appearance="white"
                                                external
                                                :to="slide.link2"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="swiper-button-prev swiper-button--white lg:-mt-8 left-5 lg:left-8 top-[unset] bottom-[3%] lg:bottom-0 lg:top-[50%]"
                    ></div>
                    <div
                        class="swiper-button-next swiper-button--white lg:-mt-8 right-5 lg:right-8 top-[unset] bottom-[3%] lg:bottom-0 lg:top-[50%]"
                    ></div>
                    <div class="absolute bottom-6 w-full z-1 left-0 lg:left-auto">
                        <div class="swiper-pagination swiper-pagination--white translate-y-2"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container -mt-4 lg:mt-0">
            {{ /* * */ }}
            {{ /* * */ }}
            {{ /* Highlighted Categories */ }}
            {{ /* * */ }}

            <HighlightedCategories
                v-if="highlightedCategories && highlightedCategories.length"
                class="z-1"
                :cats="highlightedCategories"
            />
            {{ /* * */ }}
            {{ /* * */ }}
            {{ /* Highlighted Products */ }}
            {{ /* * */ }}

            <SectionIntro
                title="Kategóriák"
                subtitle="Válogass prémium nyomtatványaink között, és adj egyedi stílust otthonodnak! Kínálatunkban különleges műalkotások széles választékát találod."
            />
            <div
                class="flex w-full flex-nowrap gap-x-4 lg:gap-x-5 items-center mt-10 lg:mt-14 justify-between pb-2 border-b border-black border-opacity-5 nicescroll-medium overflow-x-scroll lg:overflow-x-visible mb-6"
            >
                <div class="category-navigation flex items-center justify-center w-full">
                    <div class="flex items-center justify-center overflow-x-scroll nicescroll-medium px-4 gap-x-6 pb-1">
                        <nuxt-link
                            v-for="category in categories"
                            :key="`category-${category.id}`"
                            :to="
                                localePath({
                                    name: 'products-category',
                                    params: { category: category.translations[0].slug },
                                })
                            "
                            class="whitespace-nowrap font-Inter-Bold text-lg bg-opacity-0 border border-black border-opacity-0 py-1 px-5 transition hover:border-opacity-100 rounded-full"
                        >
                            {{ category.translations[0].name }}
                        </nuxt-link>
                    </div>
                </div>
            </div>

            <div v-if="products.length" class="grid grid-cols-2 lg:grid-cols-12 xl:grid-cols-10 gap-3 lg:gap-4 mb-12">
                <div
                    v-for="product in products.slice(0, 30)"
                    :key="product.id"
                    class="h-full"
                    :class="product.has_banner ? 'lg:col-span-12' : 'col-span-1 lg:col-span-3 xl:col-span-2'"
                >
                    <div class="h-full" :class="product.has_banner && 'grid grid-cols-12 '">
                        <div
                            v-if="product.has_banner"
                            class="col-span-12 lg:col-span-9 h-full w-full bg-black relative overflow-hidden rounded-t-md lg:rounded-tl-md lg:rounded-tr-none lg:rounded-l-md h-[55vh] lg:h-full"
                        >
                            <img
                                class="h-full w-full absolute top-0 left-0 object-cover"
                                :src="$setImage(product.extended_info.image, '')"
                                :alt="product.name"
                            />
                            {{ product.extended_info }}
                        </div>
                        <div class="h-full" :class="product.has_banner && 'col-span-12 lg:col-span-3'">
                            <ProductCard
                                :title="product.name"
                                :rating="5"
                                :has-btn-text="false"
                                :product="product"
                                :slug="
                                    localePath({
                                        name: 'products-category-product',
                                        params: {
                                            category: product?.category_slug,
                                            product: product?.slug,
                                        },
                                    })
                                "
                                :brutto-price="product.price"
                                :is-favorite="product.isFavorite"
                                :price="product.price"
                                :discounted-price="product?.discounted_price"
                                :md="$device.isMobile"
                                :image="product.category_name === 'Whispers of Wood' ? product.image : product.preview"
                                :is-semi-rounded="product?.has_banner"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center items-center">
                <more-button :to="localePath('products')" label="Minden termék" class="mb-8 lg:mb-12" />
            </div>

            {{ /* * */ }}
            {{ /* * */ }}
            {{ /* Editors */ }}
            {{ /* * */ }}
            <SectionIntro
                title="Szerkesztők"
                subtitle="Tedd még különlegesebbé a pillanataidat egyedi, személyre szabott termékekkel."
            />

            <div class="grid grid-cols-12 gap-y-8 lg:gap-x-8 lg:gap-y-12 mb-20 lg:mb-32">
                <div class="col-span-12 lg:col-span-6">
                    <EditorPreviewCard
                        title="Csillagtérkép szerkesztő"
                        :image="
                            require('~/assets/images/dev/product_category_previews/product_category_preview_image-6-b.webp')
                        "
                        slug="editors-star-map"
                        subtitle="Válassz dátumot, témát, és testreszabható címeket, hogy létrehozd a tökéletes csillagállást, amely a legszebb pillanataidat örökíti meg."
                        wide
                    />
                </div>
                <div class="col-span-12 lg:col-span-6">
                    <EditorPreviewCard
                        title="Térkép szerkesztő"
                        :image="
                            require('~/assets/images/dev/product_category_previews/product_category_preview_image-7.webp')
                        "
                        slug="editors-map"
                        subtitle="Jelölj meg helyszínt, dátumot és testreszabható címeket, hogy létrehozd a tökéletes térképet, amely a legszebb pillanataidat örökíti meg."
                        wide
                    />
                </div>
            </div>

            {{ /* * */ }}
            {{ /* * */ }}
            {{ /* Featured Product */ }}
            {{ /* * */ }}

            <div
                class="grid grid-cols-12 mt-12 lg:mt-32 mb-20 xl:mb-32 lg:gap-x- bg-white box-shadow rounded-lg overflow-hidden"
            >
                <div
                    class="col-span-12 lg:col-span-6 lg:pr-20 flex flex-col items-start justify-center py-8 px-4 lg:pl-12"
                >
                    <h2
                        class="font-Inter-Bold mb-4 pr-4 lg:pr-0 leading-9 lg:leading-[2.75rem] lg:mb-8 text-xl lg:text-3xl"
                    >
                        {{ highlightedProducts?.name }}
                    </h2>
                    <h3 class="text-lg lg:text-xl font-Inter-Bold mb-4 leading-6 lg:leading-8">
                        {{ highlightedProducts?.short_description }}
                    </h3>
                    <div
                        class="highlighted-product-description mb-6 lg:mb-10 leading-5 lg:leading-7"
                        v-html="highlightedProducts?.description"
                    ></div>
                    <PButton
                        label="Megtekintem"
                        blue
                        :to="
                            localePath({
                                name: 'products-category-product',
                                params: {
                                    category: highlightedProducts?.category_slug,
                                    product: highlightedProducts?.slug,
                                },
                            })
                        "
                    />
                </div>
                <nuxt-link
                    :to="
                        localePath({
                            name: 'products-category-product',
                            params: {
                                category: highlightedProducts?.category_slug,
                                product: highlightedProducts?.slug,
                            },
                        })
                    "
                    class="col-span-12 lg:col-span-6"
                >
                    <img
                        v-if="
                            highlightedProducts?.preview &&
                            highlightedProducts?.gallery &&
                            highlightedProducts?.gallery.length
                        "
                        class="mt-6 lg:mt-0 w-full"
                        :src="highlightedProducts?.gallery[0].image || highlightedProducts.preview"
                        :alt="highlightedProducts?.name"
                        data-not-lazy
                    />
                </nuxt-link>
            </div>

            {{ /* * */ }}
            {{ /* * */ }}
            {{ /* Featured Offer */ }}
            {{ /* * */ }}

            <SectionIntro v-if="featuredOffers && featuredOffers.length" title="Kiemelt ajánlat" />
            <div
                v-if="featuredOffers && featuredOffers.length"
                class="grid grid-cols-12 p-4 lg:p-8 lg:rounded-lg gap-y-4 lg:gap-y-0 gap-x-4 lg:gap-x-8 -ml-5 lg:ml-0 w-[calc(100%+2.5rem)] mb-12 xl:mb-32"
                :style="`background-color:${featuredOffers?.color || '#F6F6F6'}`"
            >
                <div class="col-span-12 lg:col-span-6 order-2 lg:order-1">
                    <div class="lg:h-[calc(100%+2rem)] flex items-center justify-center">
                        <img
                            v-if="featuredOffers?.image"
                            class="object-contain h-auto lg:px-16 h-full mb-2 mt-6 lg:mt-0 lg:-mb-8"
                            :src="featuredOffers.image"
                            alt="Kiemelt ajánlat"
                            data-not-lazy
                        />
                    </div>
                </div>
                <div
                    v-for="product in featuredOffers?.connected_products"
                    :key="product.id"
                    class="col-span-12 md:col-span-6 lg:col-span-3 order-1 lg:order-2"
                >
                    <ProductCard
                        :title="product.name"
                        :rating="5"
                        :product="product"
                        :rounded="false"
                        :slug="
                            localePath({
                                name: 'products-category-product',
                                params: {
                                    category: product.category_slug,
                                    product: product.slug,
                                },
                            })
                        "
                        :brutto-price="product.price"
                        :is-favorite="product.isFavorite"
                        :md="$device.isMobile"
                        :price="product.price"
                        :image="product.category_name === 'Whispers of Wood' ? product.image : product.preview"
                    />
                </div>
            </div>

            {{ /* * */ }}
            {{ /* * */ }}
            {{ /* Magazine */ }}
            {{ /* * */ }}
            <SectionIntro
                title="Magazin"
                subtitle="Fedezd fel magazinunkban a legújabb trendeket és kreatív ötleteket. Olvass el praktikus cikkeket, amelyek segítenek egyedi stílust alkotni otthonodban."
            />
            <div class="grid grid-cols-12 gap-y-4 lg:gap-6">
                <div class="col-span-12 lg:col-span-8">
                    <ArticleCard
                        v-if="highlightedArticles[0]"
                        :title="highlightedArticles[0].title"
                        :img-src="highlightedArticles[0].image"
                        :description="highlightedArticles[0].lead"
                        :url="
                            localePath({
                                name: 'magazine-article',
                                params: {
                                    article: highlightedArticles[0].slug,
                                },
                            })
                        "
                        :category="highlightedArticles[0].category"
                        :publish-date="highlightedArticles[0].public_date"
                        minutes="4"
                    />
                </div>
                <div class="col-span-12 lg:col-span-4">
                    <div v-if="highlightedArticles[0]?.connected_articles" class="flex flex-col gap-y-4">
                        <ArticleCard
                            v-for="article in highlightedArticles[0]?.connected_articles.slice(0, 4)"
                            :key="article.id"
                            :title="article.title"
                            :img-src="article.image"
                            :description="article.lead"
                            :url="
                                localePath({
                                    name: 'magazine-article',
                                    params: {
                                        article: article.slug,
                                    },
                                })
                            "
                            :category="article.category"
                            minutes="4"
                            :publish-date="article.public_date"
                            sm
                        />
                    </div>
                </div>
                <div class="col-span-12 lg:col-span-6">
                    <NewsletterForm has-labels class="h-full" is-card />
                </div>

                <div
                    v-for="article in magazine.filter((item) => item !== highlightedArticles[0]).slice(0, articleCount)"
                    :key="article.id"
                    class="col-span-12 lg:col-span-3"
                >
                    <ArticleCard
                        :title="article.title"
                        :img-src="article.image"
                        :description="article.lead"
                        :url="
                            localePath({
                                name: 'magazine-article',
                                params: {
                                    article: article.slug,
                                },
                            })
                        "
                        :category="article.category"
                        minutes="5"
                        :publish-date="article.public_date"
                        md
                    />
                </div>
                <div v-if="magazine.length > articleCount" class="col-span-12 mt-8">
                    <div class="flex w-full justify-center">
                        <MoreButton :cb="loadMoreArticles" />
                    </div>
                </div>
            </div>

            {{ /* * */ }}
            {{ /* * */ }}
            {{ /* Banner */ }}
            {{ /* * */ }}

            <BannerZone
                v-if="banners"
                :slug="banners.link"
                :title="banners.title_hu"
                :mobile-image="banners.mobile_image"
                :desktop-image="banners.image"
                class="lg:mt-12 xl:mb-0"
            />
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex';
import ProductCard from '@/components/Cards/ProductCard/ProductCard.vue';
import BannerZone from '@/components/BannerZone.vue';
import HighlightedCategories from '@/components/Sections/HighlightedCategories.vue';
import NewsletterForm from '@/components/Forms/NewsletterForm.vue';
import ArticleCard from '@/components/Cards/ArticleCard/ArticleCard.vue';
import EditorPreviewCard from '@/components/Cards/EditorPreviewCard/EditorPreviewCard.vue';
import MoreButton from '@/components/UI/Buttons/MoreButton.vue';

export default {
    name: 'Index',
    components: {
        NewsletterForm,
        ProductCard,
        BannerZone,
        HighlightedCategories,
        ArticleCard,
        EditorPreviewCard,
        MoreButton,
    },
    async asyncData({ $axios }) {
        let products = [];
        let magazine = [];
        let carousel = [];
        let banners = [];
        let categories = [];
        let highlightedCategories = [];
        let featuredOffers = [];
        let highlightedArticles = null;
        let highlightedProducts = [];

        try {
            await Promise.allSettled([
                $axios.$get(`/products`),
                $axios.$get(`/articles`),
                $axios.$get(`/carousels`),
                $axios.$get(`/banners`),
                $axios.$get(`/categories`),
                $axios.$get('/highlighted-categories'),
                $axios.$get('/featured-offers'),
                $axios.$get('/highlighted-products'),
            ])
                .then(
                    ([
                        productRes,
                        magazineRes,
                        carouselRes,
                        bannersRes,
                        categoryRes,
                        highlightedCategoriesRes,
                        featuredOffersRes,
                        highlightedProductsRes,
                    ]) => {
                        if (productRes.status === 'fulfilled' && productRes.value.result === 'success') {
                            products = productRes.value.data;
                        }
                        if (magazineRes.status === 'fulfilled' && magazineRes.value.result === 'success') {
                            magazine = magazineRes.value.data.filter(
                                (article) => article.category !== 'Whispers of Wood'
                            );
                            highlightedArticles = magazineRes.value.data
                                .filter((article) => article.highlighted === 1)
                                .filter((article) => article.category !== 'Whispers of Wood');
                        }
                        if (carouselRes.status === 'fulfilled' && carouselRes.value.result === 'success') {
                            carousel = carouselRes.value.data;
                        }
                        if (bannersRes.status === 'fulfilled' && bannersRes.value.result === 'success') {
                            banners = bannersRes.value.data.find((banner) => banner.name === 'home-page');
                        }
                        if (categoryRes.status === 'fulfilled' && categoryRes.value.result === 'success') {
                            categories = categoryRes.value.data;
                        }
                        if (
                            highlightedCategoriesRes.status === 'fulfilled' &&
                            highlightedCategoriesRes.value.result === 'success'
                        ) {
                            highlightedCategories = highlightedCategoriesRes.value.data;
                        }
                        if (featuredOffersRes.status === 'fulfilled' && featuredOffersRes.value?.result === 'success') {
                            featuredOffers = featuredOffersRes.value.data[0];
                        }
                        if (
                            highlightedProductsRes.status === 'fulfilled' &&
                            highlightedProductsRes.value.result === 'success'
                        ) {
                            highlightedProducts = highlightedProductsRes.value.data[0];
                        }
                    }
                )
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
        return {
            products,
            magazine,
            carousel,
            banners,
            highlightedArticles,
            categories,
            highlightedCategories,
            featuredOffers,
            highlightedProducts,
        };
    },
    data() {
        return {
            articleCount: 7,
        };
    },
    computed: {
        ...mapState(['liked_products']),
    },

    beforeDestroy() {
        this.mainCarousel.destroy();
    },
    mounted() {
        this.mainCarousel = new this.$swiper('#main-carousel', {
            slidesPerView: 1,
            spaceBetween: 0,
            resistance: true,
            resistanceRatio: 0,
            speed: 750,
            navigation: {
                nextEl: '#main-carousel .swiper-button-next',
                prevEl: '#main-carousel .swiper-button-prev',
            },
            pagination: {
                el: '#main-carousel .swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            loop: true,
            watchSlidesProgress: false,
            autoplay: {
                delay: 10000,
            },
        });
        setTimeout(() => {
            this.$store.commit('globals/setIsPageLoading', false);
            this.$cookies.set('the_first_load_is_done', true, {
                expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
            });
        }, 2000);
    },
    methods: {
        fetchData(url) {
            return this.$axios.get(url);
        },
        loadMoreArticles() {
            if (this.magazine?.length > this.articleCount) {
                this.articleCount = this.articleCount + 4;
            }
        },
    },
};
</script>

<style>
.carousel-inner {
    .carousel-inner-overlay {
        @apply absolute right-0 bottom-0 h-full lg:h-3/5 w-full pointer-events-none;
    }
    .carousel-inner-overlay--dark {
        background: linear-gradient(360deg, rgb(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 33%);
    }
}

.box-shadow {
    box-shadow: 0 4px 60px 0 rgba(21, 33, 55, 0.08);
}

.highlighted-product-description {
    @apply font-Inter-Regular text-sm lg:text-base;

    strong,
    p strong {
        @apply font-Inter-Bold mb-2 block;
    }
}
</style>
