<template>
    <nuxt-link :to="localePath(slug)" class="flex flex-col group">
        <div
            class="rounded-md overflow-hidden mb-4 aspect-w-4 aspect-h-3 shadow-lg"
            :class="{
                'lg:aspect-w-4 lg:aspect-h-3': wide,
                'lg:aspect-w-1 lg:aspect-h-1': !wide,
                'lg:min-h-[24.6875rem]': lg,
            }"
        >
            <img class="object-cover group-hover:scale-[1.04] transition" :src="image" :alt="title" data-not-lazy />
        </div>
        <div class="flex flex-col justify-between">
            <div>
                <h2 class="font-Inter-Bold mb-2 lg:mb-3" :class="md ? 'text-lg' : 'text-[1.25rem]'">{{ title }}</h2>
                <h3 class="text-sm lg:text-base font-Inter-Regular mb-3 opacity-70 leading-5">{{ subtitle }}</h3>
            </div>
            <span class="flex items-center font-Inter-Bold"
                >Részletek
                <i class="ri-arrow-right-s-line leading-none text-xl ml-1 transition group-hover:translate-x-1"></i
            ></span>
        </div>
    </nuxt-link>
</template>

<script>
export default {
    name: 'EditorPreviewCard',
    props: {
        title: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        slug: {
            type: String,
            required: true,
        },
        wide: {
            type: Boolean,
            required: false,
            default: false,
        },
        md: {
            type: Boolean,
            required: false,
            default: false,
        },
        lg: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
.transition--slow {
    transition-duration: 0.5s;
}
</style>
